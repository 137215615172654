<template>
  <div>
    <app-breadcrumb
      v-if="permission.mitraAdd"
      action="Tambah Akun Leader Baru"
      link="/leader/tambah"
    />
    <b-card no-body>
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="8"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Tampilkan</label>
            <v-select
              v-model="perPage"
              dir="ltr"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
              @close="getLeader"
            />
            <label>data</label>
          </b-col>
          <b-col
            cols="12"
            lg="4"
            md="4"
            sm="6"
            class="d-flex align-items-center justify-content-end mt-1"
          >
            <router-link to="/wakil_leader">
              Data Wakil Leader
            </router-link>
          </b-col>
          <b-col
            cols="12"
            lg="4"
            md="3"
            sm="6"
            class="mt-1"
          >
            <b-button
              variant="primary"
              block
              class="d-flex justify-content-between p-50"
              @click="findLeader()"
            >
              Cari Leader
              <feather-icon
                icon="SearchIcon"
                size="16"
                class="mr-25"
              />
            </b-button>
          </b-col>
          <vue-bottom-sheet
            ref="searchLeaderBottomSheet"
            class="p-1"
            :rounded="false"
          >
            <div class="bottom-sheet">
              <div class="d-flex justify-content-between align-items-center">
                <h4>Cari Leader</h4>
                <feather-icon
                  icon="XIcon"
                  size="28"
                  class="text-primary"
                  @click="closeFindLeader()"
                />
              </div>
              <ais-instant-search
                :search-client="searchClient"
                index-name="leader"
              >
                <ais-search-box
                  placeholder="Cari leader (bisa gunakan nama atau nomor hp)"
                  :class-names="{
                    'ais-SearchBox-input': 'form-control',
                    'ais-SearchBox-submit': 'd-none',
                    'ais-SearchBox-reset': 'd-none',
                  }"
                >
                  <div
                    slot="submit-icon"
                  >
                    Cari
                  </div>
                </ais-search-box>
                <ais-hits
                  :class-names="{
                    'ais-Hits-list' : 'hitsList',
                  }"
                >
                  <div
                    slot="item"
                    slot-scope="{ item }"
                    class="mt-1"
                  >
                    <b-card
                      border-variant="primary"
                      class="m-0"
                    >
                      <h6 class="text-primary font-weight-bolder">
                        {{ item.nama }}
                      </h6>
                      <p class="text-secondary m-0">
                        {{ item.no_hp }}
                      </p>
                    </b-card>
                  </div>
                </ais-hits>
              </ais-instant-search>
            </div>
          </vue-bottom-sheet>
        </b-row>
      </div>

      <b-table
        ref="refMitraListTable"
        :items="filteredLeader"
        responsive
        :fields="tableColumns"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Data masih belum tersedia"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
      >
        <template #cell(nama)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.fotoProfil"
                :text="avatarText(data.item.nama)"
                :variant="`light-primary`"
              />
            </template>
            <router-link :to="{ name: 'DetailLeader', params: {id: data.item.id} }">
              <p class="font-weight-bold d-block text-nowrap text-primary m-0">
                {{ data.item.nama }}
              </p>
            </router-link>
            <small class="text-muted">{{ data.item.email }}</small>
          </b-media>
        </template>
        <template #cell(no_hp)="data">
          {{ data.value }}
        </template>
        <template #cell(kota)="data">
          <p class="text-capitalize m-0">
            {{ areaLeader(data.value) }}
          </p>
        </template>
        <template #cell(layanan)="data">
          {{ vertikalMitra(data.item.layanan) }}
        </template>
        <template #cell(status_aktif)="data">
          <b-badge :variant="statusLeader(data.value).variant">
            {{ statusLeader(data.value).display }}
          </b-badge>
        </template>
        <template
          v-if="permission.mitraUpdate || permission.mitraDelete"
          #cell(aksi)="data"
        >
          <div class="text-nowrap">
            <!-- Dropdown -->
            <b-dropdown
              variant="link"
              toggle-class="p-0"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item
                v-if="permission.mitraUpdate"
                @click="$router.push({ name: 'EditLeader', params: {id: data.item.id} })"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Ubah Data Leader</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="data.item.status_aktif !== 1 && permission.mitraBlock"
                @click="aktifkanLeader(data.item.id)"
              >
                <feather-icon
                  class="text-primary"
                  icon="CopyIcon"
                />
                <span class="align-middle ml-50 text-primary">Aktifkan Akun</span>
              </b-dropdown-item>
              <b-dropdown-divider />
              <b-dropdown-item
                v-if="permission.mitraDelete"
                @click="hapusLeader(data.item.id)"
              >
                <feather-icon
                  class="text-danger"
                  icon="CopyIcon"
                />
                <span class="align-middle ml-50 text-danger">Hapus Data Leader</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
      </b-table>
      <div
        v-if="loadMorebtn"
        class="mx-auto mb-2"
      >
        <button
          class="btn btn-primary"
          @click="loadMore"
        >
          <feather-icon icon="ChevronDownIcon" />
          Lihat lebih banyak
        </button>
      </div>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable object-curly-newline */
/* eslint-disable vue/no-unused-components */
/* eslint-disable arrow-body-style */
import {
  BCard, BRow, BCol, BTable, BMedia, BAvatar, BButton,
  BBadge, BDropdown, BDropdownItem, BDropdownDivider,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import VueBottomSheet from '@webzlodimir/vue-bottom-sheet'
import algoliasearch from 'algoliasearch/lite'
import { AisInstantSearch, AisSearchBox } from 'vue-instantsearch'
import permission from '@/permission'
import regionJson from '@/db/region.json'
import { db } from '@/firebase'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BMedia,
    BAvatar,
    BBadge,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    AppBreadcrumb,
    BButton,
    algoliasearch,
    AisInstantSearch,
    AisSearchBox,

    vSelect,
    VueBottomSheet,
  },
  data() {
    return {
      searchClient: algoliasearch(
        '4LASHL8CBV',
        '0f873fc12e5ca3f430fbe1014a65c384',
      ),
      permission: permission.access,
      perPage: 40,
      region: regionJson,
      perPageOptions: [40, 60, 100],
      statusFilter: { value: null, label: 'Semua Status' },
      statusOptions: [
        { value: 0, label: 'Nonaktif' },
        { value: 1, label: 'Aktif' },
      ],
      layananFilter: { value: '0', label: 'Semua Layanan' },
      layananOptions: [
        { value: '0', label: 'Semua Layanan' },
        { value: 'vl01', label: 'Akupunktur' },
        { value: 'vl02', label: 'Auto' },
        { value: 'vl03', label: 'Barber' },
        { value: 'vl04', label: 'Bekam' },
        { value: 'vl05', label: 'Beauty' },
        { value: 'vl06', label: 'Cleaning' },
        { value: 'vl07', label: 'Massage' },
        { value: 'vl08', text: 'Cuci AC' },
      ],
      isSortDirDesc: false,
      tableColumns: [
        { key: 'nama', label: 'Nama', sortable: true },
        { key: 'no_hp', label: 'Nomor HP', sortable: true },
        { key: 'kota', sortable: false },
        { key: 'layanan', sortable: false },
        { key: 'status_aktif', label: 'status', sortable: false },
        { key: 'aksi', label: '' },
      ],
      listLeader: [],
      filteredLeader: [],
      totalMitra: 0,
      avatarText,
      sortBy: 'nama',
      loadMorebtn: true,
      searchQuery: '',
    }
  },
  created() {
    this.getLeader()
  },
  methods: {
    async getLeader() {
      /* eslint-disable no-unused-vars */
      const lastVisible = this.listLeader.length
      const limit = this.perPage <= 20 ? 20 : this.perPage
      const query = db.collection('mecarehub').where('role', '==', 'leader').orderBy('nama', 'asc')
      const filterStatus = this.statusFilter.value
      this.loadMorebtn = true

      if (filterStatus === null) {
        query.get().then(querySnapshot => {
          const arr = []
          querySnapshot.forEach(doc => {
            const vertikal = doc.data().access.mitra.area[0]
            const layanan1 = vertikal.split('-')[0]
            const kota1 = vertikal.split('-')[1]
            arr.push({
              id: doc.id,
              email: doc.data().email,
              nama: doc.data().nama,
              no_hp: doc.data().no_hp,
              kota: kota1,
              layanan: layanan1,
              status_aktif: doc.data().status,
            })
          })
          this.listLeader = [...new Set(arr)]
          this.filteredLeader = [...new Set(arr)]

          if (this.layananFilter.value !== '0') {
            this.applyFilterLayanan()
          }
        })
      } else {
        query.where('status', '==', filterStatus).get().then(querySnapshot => {
          const arr = []
          querySnapshot.forEach(doc => {
            arr.push({
              id: doc.id,
              nama: doc.data().nama,
              email: doc.data().email,
              no_hp: doc.data().no_hp,
              kota: 'Surabaya',
              layanan: 'vl01',
              status_aktif: doc.data().status,
            })
          })
          if (arr.length === 0) {
            this.loadMorebtn = false
          }

          this.listLeader = [...new Set(arr)]
          this.filteredLeader = [...new Set(arr)]

          if (this.layananFilter.value !== '0') {
            this.applyFilterLayanan()
          }
        })
      }
    },
    areaLeader(area) {
      const fromjson = regionJson.find(item => {
        return item.id === area
      })

      const region = fromjson.name.split(' ')

      region.shift()

      const kota = region.join(' ')

      return kota.toLowerCase()
    },
    loadMore() {
      if (this.listLeader.length === this.perPage) {
        this.perPage += 20
        this.getLeader()
      } else {
        this.loadMorebtn = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Data sudah ditampilkan semua',
            icon: 'CheckIcon',
            variant: 'success',
            position: 'bottom',
          },
        })
      }
    },
    applyFilterLayanan() {
      /* eslint-disable arrow-body-style */
      if (this.layananFilter.value === '0') {
        this.getLeader()
      } else {
        const filtered = this.listLeader.filter(item => {
          return item.layanan === this.layananFilter.value
        })

        this.filteredLeader = [...new Set(filtered)]
      }
    },
    cariMitra() {
      db.collection('mecarehub').doc(this.searchQuery).get().then(snapshot => {
        this.filteredLeader = [snapshot.data()]
      })
    },
    vertikalMitra(vl) {
      const vertikalVariant = {
        vl01: 'Akupunktur',
        vl02: 'Auto',
        vl03: 'Barber',
        vl04: 'Bekam',
        vl05: 'Beauty',
        vl06: 'Cleaning',
        vl07: 'Massage',
        vl08: 'Cuci AC',
      }

      return vertikalVariant[vl]
    },
    statusLeader(status) {
      if (status === 0) return { variant: 'light-danger', display: 'Tidak Aktif' }
      if (status === 1) return { variant: 'light-primary', display: 'Aktif' }
      return { variant: 'light-secondary', display: 'tidak diketahui' }
    },
    hapusLeader(id) {
      this.$swal({
        title: 'Hapus data leader?',
        text: 'Konfirmasi jika anda ingin menghapus data leader',
        showCancelButton: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          db.collection('mecarehub').doc(`${id}`).delete().then(() => {
            this.getLeader()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Berhasil menghapus data leader',
                icon: 'TrashIcon',
                variant: 'success',
              },
            })
          })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error.value,
                  icon: 'EditIcon',
                  variant: 'warning',
                },
              })
            })
        }
      })
    },
    aktifkanLeader(id) {
      this.$swal({
        title: 'Aktifkan leader?',
        text: 'Konfirmasi jika anda ingin mengaktifkan leader',
        showCancelButton: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          db.collection('mecarehub').doc(id).set({
            status: 1,
            terakhir_diedit: +new Date(),
          }, { merge: true }).then(() => {
            this.getLeader()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Akun leader berhasil diaktifkan',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          })
        }
      })
    },
    findLeader() {
      this.$refs.searchLeaderBottomSheet.open()
    },
    closeFindLeader() {
      this.$refs.searchLeaderBottomSheet.close()
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.Mitra-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
.bottom-sheet {
  padding: 16px 16px 200px 16px
}
</style>

<style>
  .hitsList {
    list-style-type: none !important;
    padding-left: 0;
  }
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
